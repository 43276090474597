import {
  deleteParticipantFromRace,
  editParticipantInRace,
  editParticipantBib,
  getParticipantById,
  getParticipantsInRace,
  addParticipantInRace,
  generateParticipantExcel,
  uploadParticipants,
} from "./requests/participantRequests";

const useParticipants = () => {
  const deleteParticipantHandler = async (raceId, participantId) => {
    try {
      const response = await deleteParticipantFromRace(raceId, participantId);
      return response;
    } catch (error) {
      console.error("Failed to delete participant", error);
    }
  };

  const editParticipantHandler = async (
    raceId,
    participantId,
    participantData
  ) => {
    try {
      const response = await editParticipantInRace(
        raceId,
        participantId,
        participantData
      );
      return response;
    } catch (error) {
      console.error("Failed to edit participant", error);
    }
  };

  const editParticipantBibHandler = async (raceId, participantId, data) => {
    try {
      const response = await editParticipantBib(raceId, participantId, data);
      return response;
    } catch (error) {
      console.error("Failed to edit participant bib", error);
    }
  };

  const fetchParticipantByIdHandler = async (raceId, participantId) => {
    try {
      const response = await getParticipantById(raceId, participantId);
      return response;
    } catch (error) {
      console.error("Failed to fetch participant", error);
    }
  };

  const fetchParticipantsHandler = async (raceId) => {
    try {
      const response = await getParticipantsInRace(raceId);
      return response;
    } catch (error) {
      console.error("Failed to fetch participants", error);
    }
  };

  const addParticipantHandler = async (raceId, participantData) => {
    try {
      const response = await addParticipantInRace(raceId, participantData);
      return response;
    } catch (error) {
      console.error("Failed to add participant", error);
    }
  };

  const generateParticipantExcelHandler = async (raceId) => {
    try {
      const response = await generateParticipantExcel(raceId);
      return response;
    } catch (error) {
      console.error("Failed to generate excel", error);
    }
  };

  const uploadParticipantsHandler = async (raceId, participantsData) => {
    try {
      const response = await uploadParticipants(raceId, participantsData);
      return response;
    } catch (error) {
      console.error("Failed to upload participants", error);
    }
  };
  return {
    deleteParticipantHandler,
    editParticipantHandler,
    editParticipantBibHandler,
    fetchParticipantByIdHandler,
    fetchParticipantsHandler,
    addParticipantHandler,
    generateParticipantExcelHandler,
    uploadParticipantsHandler,
  };
};

export default useParticipants;
