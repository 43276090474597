import axios from "axios";

const deleteParticipantFromRace = async (raceId, participantId) => {
  try {
    const response = await axios.delete(
      `/race/${raceId}/participant/${participantId}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to delete participant from race", error);
  }
};

const editParticipantInRace = async (
  raceId,
  participantId,
  participantData
) => {
  try {
    const response = await axios.put(
      `/race/${raceId}/participant/${participantId}`,
      participantData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to edit participant in race", error);
  }
};

const editParticipantBib = async (raceId, participantId, data) => {
  try {
    const response = await axios.put(
      `/race/${raceId}/participant/${participantId}/bib`,
      { data }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to edit participant bib", error);
  }
};

const getParticipantById = async (raceId, participantId) => {
  try {
    const response = await axios.get(
      `/race/${raceId}/participant/${participantId}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to get participant by id", error);
  }
};

const getParticipantsInRace = async (raceId) => {
  try {
    const response = await axios.get(`/race/${raceId}/participants`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch participants, error");
  }
};

const addParticipantInRace = async (raceId, participantData) => {
  try {
    const response = await axios.post(
      `/race/${raceId}/participant`,
      participantData
    );
    return response;
  } catch (error) {
    console.error("Failed to add participant in race", error);
  }
};
const generateParticipantExcel = async (raceId) => {
  try {
    const response = await axios.post(`/race/${raceId}/participantsToExcel`, {
      responseType: "blob", // Important to specify the response type as blob
    });
    return response.data;
  } catch (error) {
    console.error("Failed to generate excel", error);
  }
};
const uploadParticipants = async (raceId, participantsData) => {
  try {
    const response = await axios.post(
      `/race/upload/${raceId}`,
      participantsData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to upload participants", error);
  }
};

export {
  deleteParticipantFromRace,
  editParticipantInRace,
  editParticipantBib,
  getParticipantById,
  getParticipantsInRace,
  addParticipantInRace,
  generateParticipantExcel,
  uploadParticipants,
};
