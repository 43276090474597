import React, { useMemo, useState, useEffect, useReducer } from "react";
import { IMAGES, SVGICON } from "../../constant/theme";
import CountUp from "react-countup";
import { Dropdown, Button, Col, Row, Modal } from "react-bootstrap";
import { useParams, NavLink, Link } from "react-router-dom";
import swal from "sweetalert";
import { useTable, useSortBy } from "react-table";
import ScrollToTop from "../../layouts/ScrollToTop";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./customCss/xclx.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import useEvents from "../../../hooks/useEvents";
import useRaces from "../../../hooks/useRaces";
import useSplitTimes from "../../../hooks/useSplitTimes";
import useParticipants from "../../../hooks/useParticipants";

const initialState = {
  editParticipantModal: false,
  selectedParticipant: null,
};

const reducer = (state, active) => {
  switch (active.type) {
    case "editParticipantModal":
      return {
        ...state,
        editParticipantModal: !state.editParticipantModal,
        selectedParticipant: active.payload,
      };

    default:
      return state;
  }
};

const RaceDetails = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [editedParticipantData, setEditedParticipantData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    age: "",
  });
  const [data, setData] = useState([]);

  const { raceId, eventId } = useParams();
  const [race, setRace] = useState({});
  const [participants, setParticipants] = useState([]);
  const [event, setEvent] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState();
  const [splitTimes, setSplitTimes] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const participants_COLUMNS = [
    {
      Header: "Bib Number",
      accessor: "bibNumber",
    },
    {
      Header: "Name",
      accessor: "firstName",
    },
    {
      Header: "Surname",
      accessor: "lastName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Age",
      accessor: "age",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
  ];
  const columns = useMemo(() => participants_COLUMNS, []);
  const tableInstance = useTable({ columns, data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  const [counterBlog, setCounterBlog] = useState([
    {
      title: "Total Participants",
      count: 0,
      colorstyle: "success",
      icon: SVGICON.Complete,
    },
    {
      title: "Splits",
      count: 0,
      colorstyle: "primary",
      icon: SVGICON.Progress,
    },
    {
      title: "Distance in km",
      count: "0 km",
      colorstyle: "purple",
      icon: SVGICON.Started,
    },
    {
      title: "Trackers",
      count: 0,
      colorstyle: "danger",
      icon: SVGICON.Canceled,
    },
  ]);
  const [loadState, setLoadState] = useState(true);
  const [selectedGender, setSelectedGender] = useState(null);

  const { fetchEventById } = useEvents();

  const { fetchRaceByIds, updateRaceTimeHandler } = useRaces();

  const { fetchSplitTimesForRaceGenerate, fetchSplitTimesForEditConfirmed } =
    useSplitTimes();

  const {
    fetchParticipantByIdHandler,
    deleteParticipantHandler,
    generateParticipantExcelHandler,
    editParticipantHandler,
  } = useParticipants();

  useEffect(() => {
    const fetchRace = async () => {
      try {
        const response = await fetchRaceByIds(raceId, eventId);
        const splitTimes = await fetchSplitTimesForRaceGenerate(raceId);
        const participants = response.race.participants
          .filter((participant) => participant.isApproved === true)
          .map((participant) => ({
            ...participant,
            age: participant.age || "N/A",
            email: participant.email || "N/A",
          }));
        setRace(response.race);
        setIsButtonDisabled(
          response.race.status === 1 ||
            response.race.status === 2 ||
            response.race.isStarted
        );
        setData(participants || []);
        setParticipants(participants);
        setSplitTimes(splitTimes.splitTimes);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadState(false);
      }
    };

    const fetchEvent = async () => {
      try {
        const response = await fetchEventById(eventId);
        console.log(response);
        setEvent(response.event);
      } catch (error) {
        swal("Failed!", "Event details error!", "error");
        console.error(error);
      }
    };

    fetchEvent();
    fetchRace();
  }, [raceId]);

  useEffect(() => {
    if (race) {
      const totalParticipants = race.participants
        ? race.participants.length
        : 0;
      const totalSplits = race.splits ? race.splits.length : 0;

      const totalDistanceInKm = race.distance; // Displaying distance with two decimal places
      const totalTrackers = race.trackers ? race.trackers.length : 0;

      setCounterBlog((prevCounterBlog) => [
        {
          ...prevCounterBlog[0],
          count: totalParticipants,
        },
        {
          ...prevCounterBlog[1],
          count: totalSplits,
        },
        {
          ...prevCounterBlog[2],
          count: totalDistanceInKm,
        },
        {
          ...prevCounterBlog[3],
          count: totalTrackers,
        },
      ]);
    }
  }, [race]);

  const handleFirstNameChange = (e) => {
    const { name, value } = e.target;

    setEditedParticipantData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLastNameChange = (e) => {
    const { name, value } = e.target;

    setEditedParticipantData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;

    setEditedParticipantData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAgeChange = (e) => {
    const { name, value } = e.target;

    setEditedParticipantData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleGenderChange = (selectedOption) => {
    setSelectedGender(selectedOption.value);

    setEditedParticipantData((prevData) => ({
      ...prevData,
      gender: selectedOption.label,
    }));
  };

  const getGenderOptions = () => {
    return [
      { value: "Male" || "male", label: "Male" || "male" },
      { value: "Female" || "female", label: "Female" || "female" },
    ];
  };

  const genderOptions = getGenderOptions();

  const startTheRace = async (raceId) => {
    try {
      const currentTime = Math.floor(new Date().getTime() / 1000); // Get current time in Unix format

      const response = await updateRaceTimeHandler(raceId, currentTime);
      setIsButtonDisabled(true);
      return response;
    } catch (error) {
      throw new Error("Failed to start the race.");
    }
  };

  const fetchParticipantById = async (participantId) => {
    try {
      const response = await fetchParticipantByIdHandler(raceId, participantId);
      return response;
    } catch (error) {
      throw new Error("Failed to fetch participant data for editing.");
    }
  };

  const handleDeleteParticipant = async (participantId) => {
    try {
      const response = await fetchParticipantById(participantId);
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this participant!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        className: "text-center-swal",
      }).then(async (willDelete) => {
        if (willDelete) {
          await deleteParticipantHandler(raceId, participantId);

          const response = await fetchRaceByIds(raceId, eventId);
          const updatedRace = response.race;

          // Update the local state with the new participants
          setRace(updatedRace);
          setData(updatedRace.participants || []);

          swal("Participant has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Participant is still active!");
        }
      });
    } catch (error) {
      console.error("Failed to delete participant!", error);
    }
  };

  const handleEditParticipant = async (participantId) => {
    try {
      const response = await fetchParticipantById(participantId);
      const selectedParticipantData = response.participant;

      // Set initial state for the date input field
      setEditedParticipantData({
        ...selectedParticipantData,
        participantId,
      });

      setSelectedGender(selectedParticipantData.gender);

      dispatch({ type: "editParticipantModal", payload: response });
      setSelectedParticipant(selectedParticipantData);
    } catch (error) {
      console.error("Failed to fetch participant data for editing", error);
    }
  };

  const handleDownloadParticipantsList = async () => {
    try {
      console.log("Downloading participants list...");
      const response = await generateParticipantExcelHandler(raceId);
      console.log(response);

      // Create a URL for the blob data
      const url = window.URL.createObjectURL(new Blob([response]));

      // Create a link and click it to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${race.raceName}_participants.csv`); // Set desired file name and extension
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link
      URL.revokeObjectURL(url);
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      setButtonIsLoading(true);
      // Prepare the edited data to be sent to the backend
      const editedData = {
        ...editedParticipantData,
      };

      const { age } = editedData;

      // Check if age is a number
      if (age && !/^[0-9]+$/.test(age)) {
        toast.warn("Age field should be a number.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      // Send a PUT request to update the participant on the backend using editedEventData
      await editParticipantHandler(raceId, selectedParticipant._id, editedData);

      // Fetch the updated participants immediately after the save
      const response = await fetchRaceByIds(raceId, eventId);
      const updatedRace = response.race;

      // Update the local state with the new participants
      setRace(updatedRace);
      const updatedParticipants = updatedRace.participants.map(
        (participant) => {
          return {
            ...participant,
            age: participant.age || "N/A",
            email: participant.email || "N/A",
          };
        }
      );
      setData(updatedParticipants || []);

      // After successful update, close the modal or perform any other actions
      dispatch({ type: "editParticipantModal" });
      swal("Participant has been updated!", {
        icon: "success",
      });
    } catch (error) {
      console.error("Failed to update participant", error);
      swal("Oops", "Failed to update participant!", "error");
    } finally {
      setButtonIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <ToastContainer />
      <div className="container-fluid">
        <Card className="mb-4">
          <Card.Body>
            <Card.Header
              style={{
                height: "55px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Card.Title style={{ fontSize: "19px", marginBottom: "13px" }}>
                {event.name && (
                  <React.Fragment>
                    <Link to={`/events/${eventId}/races`}>
                      <b>{event.name}</b> Races
                    </Link>{" "}
                    &gt; <b>{race.raceName}</b> Race
                  </React.Fragment>
                )}
              </Card.Title>
              {event.name && (
                <div>
                  <Button
                    className="mb-3"
                    style={{
                      backgroundColor: "green",
                      marginRight: "10px",
                      borderColor: "green",
                      pointerEvents: "all",
                      cursor: isButtonDisabled ? "not-allowed" : "",
                    }}
                    disabled={isButtonDisabled}
                    title={
                      race.status === 1
                        ? "Race is finished"
                        : race.status === 0 && race.isStarted
                        ? "Race is started"
                        : race.status === 2
                        ? "Race is canceled"
                        : ""
                    }
                    onClick={async () => {
                      swal({
                        title: "Are you sure?",
                        text: "Once started, you will not be able to stop the race!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                        className: "text-center-swal",
                      }).then(async (willStart) => {
                        if (willStart) {
                          try {
                            await startTheRace(raceId);
                            swal(
                              "Success",
                              "Race started successfully!",
                              "success"
                            );
                          } catch (error) {
                            swal(
                              "Oops",
                              error.message || "Failed to start the race",
                              "error"
                            );
                          }
                        }
                      });
                    }}
                  >
                    Start the Race
                  </Button>

                  <NavLink
                    to={`/events/${eventId}/races/${raceId}/generate-results`}
                    style={{
                      pointerEvents:
                        splitTimes.length === 0 || race.status !== 1
                          ? "none"
                          : "auto",
                    }}
                  >
                    <Button
                      className="mb-3"
                      variant="primary"
                      disabled={splitTimes.length === 0 || race.status === 1}
                      title={
                        splitTimes.length === 0
                          ? "No results yet"
                          : race.status === 1
                          ? "Race is finished"
                          : ""
                      }
                      style={{
                        pointerEvents: "all",
                        cursor:
                          splitTimes.length === 0 || race.status === 1
                            ? "not-allowed"
                            : "",
                      }}
                    >
                      Generate Results
                    </Button>
                  </NavLink>
                </div>
              )}
            </Card.Header>

            <div className="row" style={{ marginTop: "15px" }}>
              {counterBlog.map((data, index) => (
                <div className="col-xl-3 col-sm-6" key={index}>
                  <div className="card box-hover">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div
                          className={`icon-box icon-box-lg  rounded-circle bg-${data.colorstyle}-light `}
                        >
                          {data.icon}
                        </div>
                        <div className="total-projects2 style-2 ms-3">
                          <CountUp
                            className={`text-start project-counter count text-${data.colorstyle}`}
                            end={data.count}
                            duration={5}
                          />
                          <h6>{data.title}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <Row>
              <Col lg="12">
                {loadState ? (
                  <div className="text-center mt-4">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <React.Fragment>
                      <div className="card-header" style={{ height: "70px" }}>
                        <h3 className="card-title" style={{ fontSize: "19px" }}>
                          Participants
                        </h3>
                        <div className="d-flex align-items-center">
                          <NavLink
                            to={`/events/${eventId}/races/${raceId}/new-participant`}
                            style={{
                              pointerEvents:
                                race.status === 1 ? "none" : "auto",
                            }}
                          >
                            <Button
                              className="m-3"
                              variant="secondary"
                              disabled={race.status === 1}
                              title={
                                race.status === 1 ? "Race is finished" : ""
                              }
                              style={{
                                pointerEvents: "all",
                                cursor: race.status === 1 ? "not-allowed" : "",
                              }}
                            >
                              Add Participant
                            </Button>
                          </NavLink>
                          <Button
                            className="btn-sm"
                            variant="primary"
                            onClick={handleDownloadParticipantsList}
                            disabled={participants.length === 0}
                            title={
                              participants.length === 0
                                ? "No participants available"
                                : "Download Participants (.csv)"
                            }
                            style={{
                              pointerEvents:
                                participants.length === 0 ? "auto" : "",
                              cursor:
                                participants.length === 0 ? "not-allowed" : "",
                              borderRadius: "5px",
                              backgroundColor: "green",
                              border: "none",
                              padding: "5px 9px",
                            }}
                          >
                            <FontAwesomeIcon icon={faFileExcel} size="2x" />
                          </Button>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="table-responsive">
                          <div className="dataTables_wrapper">
                            <table
                              {...getTableProps()}
                              className="table dataTable display"
                            >
                              <thead>
                                {headerGroups.map((headerGroup) => (
                                  <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                      <th
                                        className="text-center"
                                        {...column.getHeaderProps(
                                          column.getSortByToggleProps()
                                        )}
                                      >
                                        {column.render("Header")}
                                        <span className="ml-1">
                                          {column.isSorted ? (
                                            column.isSortedDesc ? (
                                              <i
                                                className="fa fa-arrow-down ms-2 fs-14"
                                                style={{ opacity: "0.7" }}
                                              />
                                            ) : (
                                              <i
                                                className="fa fa-arrow-up ms-2 fs-14"
                                                style={{ opacity: "0.7" }}
                                              />
                                            )
                                          ) : (
                                            <i
                                              className="fa fa-sort ms-2 fs-14"
                                              style={{ opacity: "0.3" }}
                                            />
                                          )}
                                        </span>
                                      </th>
                                    ))}
                                    <th className="text-center">Actions</th>
                                  </tr>
                                ))}
                              </thead>
                              <tbody {...getTableBodyProps()}>
                                {rows.map((row) => {
                                  prepareRow(row);

                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell, index) => {
                                        return (
                                          <td
                                            key={index}
                                            className="text-center"
                                            {...cell.getCellProps()}
                                          >
                                            {" "}
                                            {cell.render("Cell")}{" "}
                                          </td>
                                        );
                                      })}
                                      <td className="col-1 text-center">
                                        <Button
                                          variant="primary"
                                          className="shadow btn-xs sharp me-1"
                                          onClick={() =>
                                            handleEditParticipant(
                                              row.original._id
                                            )
                                          }
                                        >
                                          <i className="fas fa-pencil-alt"></i>
                                        </Button>
                                        <Button
                                          variant="danger"
                                          className="shadow btn-xs sharp"
                                          onClick={() =>
                                            handleDeleteParticipant(
                                              row.original._id
                                            )
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Modal
          className="fade"
          show={state.editParticipantModal}
          onHide={() => {
            dispatch({ type: "editParticipantModal" });
          }}
        >
          <Modal.Header>
            <Modal.Title>Edit Participant</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => dispatch({ type: "editParticipantModal" })}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {selectedParticipant && selectedParticipant.gender ? (
              <React.Fragment>
                <div className="form-group mb-3 col-md-12">
                  <label className="form-group-text w-25">
                    <b>Name</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    value={editedParticipantData.firstName}
                    onChange={handleFirstNameChange}
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label className="form-group-text w-25">
                    <b>Surname</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    value={editedParticipantData.lastName}
                    onChange={handleLastNameChange}
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label className="form-group-text w-25">
                    <b>Email</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={editedParticipantData.email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <span className="form-group-text w-25">
                    <b>Age</b>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    name="age"
                    value={editedParticipantData.age}
                    onChange={handleAgeChange}
                  />
                </div>
                <div className="form-group mb-3 w-100 col-md-12">
                  <label className="form-group-text w-50">
                    <b>Gender</b>
                  </label>
                  <Select
                    className="mb-10 w-100"
                    name="gender"
                    options={genderOptions}
                    value={genderOptions.find(
                      (option) => option.value === selectedGender
                    )}
                    onChange={handleGenderChange}
                  />
                </div>
              </React.Fragment>
            ) : (
              <p>No participant selected.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger light"
              onClick={() => dispatch({ type: "editParticipantModal" })}
            >
              Close
            </Button>
            <Button
              variant="primary mr-3"
              disabled={buttonIsLoading}
              onClick={() => handleSaveChanges(editedParticipantData)}
            >
              {buttonIsLoading ? "Saving changes..." : "Save changes"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default RaceDetails;
